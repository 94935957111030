import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Routes, Route, Navigate, useMatch } from 'react-router-dom';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { useSinglePrismicDocument } from '@prismicio/react';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Legal from './pages/Legal';
import NotFound from './pages/NotFound';
import PropertyDetail from './pages/PropertyDetail';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Properties from './components/Properties';
import ScrollToTop from './components/ScrollToTop';
import Services from './components/Services';
import './App.css';

export const ContextProperties = React.createContext('');
export const ContextHomepage = React.createContext('');

function App() {
  const [properties] = useAllPrismicDocumentsByType('properties');
  const [homepage] = useSinglePrismicDocument('homepage');
  const activeHome = useMatch('') !== null;
  const activeContact = useMatch('/contacto') !== null;
  const activeProperty = useMatch('/propiedades/:propertyId/:propertyName') !== null;
  const showFilters = activeHome || activeProperty;
  
  return (
    <ContextProperties.Provider value={properties}>
      <ContextHomepage.Provider value={homepage}>
        <Provider store={store}>
            <Navbar showFilters={showFilters} />
            <div style={{paddingTop: '125px'}}>
              <ScrollToTop>
                <Routes>
                  <Route path='/contacto' element={<Contact />} />
                  <Route 
                    path='/propiedades/:propertyId/:propertyName' 
                    element={<PropertyDetail data={properties} />} 
                  />
                  <Route path='/legal' element={<Legal />} />
                  <Route path='/' element={<Home />} />
                  <Route path='*' element={<Navigate to='/404' replace />} />
                  <Route path='404' element={<NotFound />} />
                </Routes>
              </ScrollToTop>
            </div>
          {
            (activeHome || activeContact || activeProperty) &&
            <Services />
          }  
          {
            (activeHome || activeProperty) &&
            <Properties />
          }
          <Footer />
        </Provider>  
      </ContextHomepage.Provider>
    </ContextProperties.Provider>
  );
}

export default App;