import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const isMobile = width < 768;

    return (
        <footer className='footer-container'>
            <h5 className='h5-title-small'>iHome Cancún</h5>
            <Link className='footer-link | h5-title-small' to='/legal'>
                { isMobile ? 'Cookies, Aviso y Términos' : 'Cookies, Aviso de Privacidad y Términos y Condiciones' }
            </Link>
        </footer>
    );
}

export default Footer;
