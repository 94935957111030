import { createSlice } from "@reduxjs/toolkit";

export const filtersModalSlice = createSlice({
    name: 'filtersModal',
    initialState: {
        value: false,
    },
    reducers: {
        openModal: state => {state.value = true},
        closeModal: state => {state.value = false},
    },
})

export const { openModal, closeModal } = filtersModalSlice.actions;

export default filtersModalSlice.reducer;