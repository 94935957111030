import { createSlice } from "@reduxjs/toolkit";

export const filterPriceSlice = createSlice({
    name: 'filterPrice',
    initialState: {
        value: [0, 0],
    },
    reducers: {
        setFilterPrice: (state, action) => {state.value = action.payload},
    },
})

export const { setFilterPrice } = filterPriceSlice.actions;

export default filterPriceSlice.reducer;