import { createSlice } from "@reduxjs/toolkit";

export const filterCategorySlice = createSlice({
    name: 'filterCategory',
    initialState: {
        value: [],
    },
    reducers: {
        setFilterCategory: (state, action) => {state.value = action.payload},
    },
})

export const { setFilterCategory } = filterCategorySlice.actions;

export default filterCategorySlice.reducer;