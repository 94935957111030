import React from 'react';
import { ContextHomepage } from '../App';
import House1 from '../icons/House1.svg';
import House2 from '../icons/House2.svg';
import House3 from '../icons/House3.svg';
import { ReactComponent as Blob1SVG } from '../icons/Blob1.svg';
import { ReactComponent as Blob2SVG } from '../icons/Blob2.svg';
import { ReactComponent as WhatsappSVG } from '../icons/Whatsapp.svg';
import './Services.css';

function Services() {
    const homepage = React.useContext(ContextHomepage);
    return (
        <div className='services-container'>
            <div className='services-card'>
                <img className='services-card-house' src={House1} alt='House' />
                <div className='services-card-info'>
                    {
                        homepage && homepage.data.title_service1[0].text &&
                        <h5 className='services-card-title | h5-title-small'>
                            {homepage.data.title_service1[0].text}
                        </h5>
                    }
                    {
                        homepage && homepage.data.text_service1[0].text &&
                        <p className='body-text-small'>
                            {homepage.data.text_service1[0].text}
                        </p>
                    }
                    {
                        homepage && homepage.data.whatsapp_main &&
                        <a 
                            className='services-btn-whatsapp | btn'
                            href={`https://api.whatsapp.com/send?phone=52${homepage.data.whatsapp_main}&text=¡Hola iHomeCancún!`}
                            target='_blank' 
                            rel='noreferrer'>
                            <WhatsappSVG className='icon-regular' />
                            <span>¡Me interesa!</span>
                        </a>
                    }
                </div>
            </div>
            <div className='services-card'>
                <img className='services-card-house' src={House2} alt='House' />
                <div className='services-card-info'>
                    {
                        homepage && homepage.data.title_service2[0].text &&
                        <h5 className='services-card-title | h5-title-small'>
                            {homepage.data.title_service2[0].text}
                        </h5>
                    }
                    {
                        homepage && homepage.data.text_service2[0].text &&
                        <p className='body-text-small'>
                            {homepage.data.text_service2[0].text}
                        </p>
                    }
                    {
                        homepage && homepage.data.whatsapp_main &&
                        <a 
                            className='services-btn-whatsapp | btn'
                            href={`https://api.whatsapp.com/send?phone=52${homepage.data.whatsapp_main}&text=¡Hola iHomeCancún!`}
                            target='_blank' 
                            rel='noreferrer'>
                            <WhatsappSVG className='icon-regular' />
                            <span>¡Me interesa!</span>
                        </a>
                    }
                </div>
            </div>
            <div className='services-card'>
                <img className='services-card-house' src={House3} alt='House' />
                <div className='services-card-info'>
                    {
                        homepage && homepage.data.title_service3[0].text &&
                        <h5 className='services-card-title | h5-title-small'>
                            {homepage.data.title_service3[0].text}
                        </h5>
                    }
                    {
                        homepage && homepage.data.text_service3[0].text &&
                        <p className='body-text-small'>
                            {homepage.data.text_service3[0].text}
                        </p>
                    }
                    {
                        homepage && homepage.data.whatsapp_main &&
                        <a 
                            className='services-btn-whatsapp | btn'
                            href={`https://api.whatsapp.com/send?phone=52${homepage.data.whatsapp_main}&text=¡Hola iHomeCancún!`}
                            target='_blank' 
                            rel='noreferrer'>
                            <WhatsappSVG className='icon-regular' />
                            <span>¡Me interesa!</span>
                        </a>
                    }
                </div>
            </div>
            <Blob1SVG className='services-container-blob1' />
            <Blob2SVG className='services-container-blob2' />
        </div>
    );
}

export default Services;
