import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Cat404SVG } from '../icons/Cat404.svg';
import { ReactComponent as ArrowReturnSVG } from '../icons/ArrowReturn.svg';
import './NotFound.css';

function NotFound() {
    return (
        <div className='notfound-container'>
            <Cat404SVG />
            <h1 className='notfound-text | h1-title-large'>Oops, algo salió mal</h1>
            <Link className='notfound-btn | btn' to='/'>
                <ArrowReturnSVG />
                <span className='notfound-btn-text'>Volver al inicio</span>
            </Link>
        </div>
    );
}

export default NotFound;
