import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import './RangeSlider.css';

function RangeSlider(data) {
  const [sliderValue, setSliderValue] = useState([0, 0]);

  useEffect(() => {
    setSliderValue(data.rangeValue);
  }, [data.rangeValue]);
  
  const handleChangeSlider = (event, newValue) => {
    setSliderValue(newValue);
  };

  return (
    <div className="rangeslider-container">
        <h2 className='filter-title'>{data.title}</h2>
        <Box sx={{ width: 190 }}>
          <Slider
            defaultValue={data.rangeValue}
            min={data.rangeValue[0]}
            max={data.rangeValue[1]}
            value={sliderValue}
            onChange={handleChangeSlider}
            valueLabelDisplay="auto"
            onChangeCommitted={() => data.dispatchFunction(sliderValue)}
            sx={{
              '& .MuiSlider-rail': {
                opacity: '1',
                height: '2px',
                color: '#656565'
              },
              '& .MuiSlider-track': {
                color: '#FFD8BC'
              },
              '& .MuiSlider-thumb': {
                color: '#D16400'
              },
              '& .MuiSlider-valueLabel': {
                backgroundColor: '#D16400'
              },
            }}
          />
        </Box>
        <span className='filter-text'>$ {Number(sliderValue[0]).toLocaleString('en')}</span>
        <span className='filter-text'> a </span>
        <span className='filter-text'>$ {Number(sliderValue[1]).toLocaleString('en')}</span>
    </div>
  );
}

export default RangeSlider;