import React from 'react';
import { ContextHomepage } from '../App';
import { ReactComponent as ArrowFormsSVG } from '../icons/ArrowForms.svg';
import { ReactComponent as CheckSVG } from '../icons/Check.svg';
import { ReactComponent as FacebookSVG } from '../icons/Facebook.svg';
import { ReactComponent as InstagramSVG } from '../icons/Instagram.svg';
import { ReactComponent as TwitterSVG } from '../icons/Twitter.svg';
import './Contact.css';

function Contact() {
    const homepage = React.useContext(ContextHomepage);

    return (
        <div className='contact-container'>
            <div className='contact-container-features'>
                {
                    homepage && homepage.data.contact_title[0].text &&
                    <h2 className="h2-title-large">{homepage.data.contact_title[0].text}</h2>
                }
                {
                    homepage && homepage.data.contact_text[0].text &&
                    <p className="contact-intro-text | body-text-large">
                        {homepage.data.contact_text[0].text}
                    </p>
                }
                {
                    homepage && homepage.data.contact_features.length > 0 &&
                    <div className="contact-features">
                        {
                            homepage.data.contact_features.map((feature, index) => {
                                return (
                                    <p
                                        key={index}
                                        className='contact-feature | body-text-large'>
                                        <CheckSVG className='icon-regular color-orange-hover' />
                                        <span>{feature.contact_feature[0].text}</span>
                                    </p>
                                );
                            })
                        }
                    </div>
                }
            </div>
            <div className='contact-container-forms'>
                <h4 className='h4-title-small text-align-center'>¡Contáctanos!</h4>
                <p className='contact-text | body-text-small text-align-center margin-auto'>Por favor, rellena el formulario y con gusto te responderemos a la brevedad</p>
                {
                    homepage && homepage.data.email &&
                    <form 
                        target='_blank' 
                        action={`https://formsubmit.co/${homepage.data.email[0].text}`}
                        method='POST'>
                        <label className='body-text-small' htmlFor='forms-name'>* Nombre Completo</label>
                        <input type='text' id='forms-name' name='name' placeholder='Nombre Completo' required />
                        <label className='body-text-small' htmlFor='forms-phone'>* Teléfono</label>
                        <input type='tel' id='forms-phone' name='phone' placeholder='Teléfono' required />
                        <label className='body-text-small' htmlFor='forms-email'>* Correo Electrónico</label>
                        <input type='email' id='forms-email' name='email' placeholder='Correo Electrónico' required />
                        <label className='body-text-small' htmlFor='forms-message'>* Mensaje</label>
                        <textarea id='forms-message' placeholder='Mensaje' name='message' rows='10' required></textarea>
                        <button className='forms-btn | btn margin-auto' type='submit'>
                            <ArrowFormsSVG className='forms-btn-icon' />
                            <span>Enviar</span>
                        </button>
                    </form>
                }
                {
                    homepage && homepage.data.whatsapp_main &&
                    <p className='body-text-small text-align-center'>
                        <span>O bien, da </span>
                        <a 
                            className='forms-whatsapp-link'
                            href={`https://api.whatsapp.com/send?phone=52${homepage.data.whatsapp_main}&text=¡Hola iHomeCancún!`}
                            target='_blank'
                            rel='noreferrer'>
                            click aquí para poder hablar vía Whatsapp
                        </a>
                    </p>
                }
                {
                    homepage &&
                    <div className='contact-forms-social'>
                        {
                            homepage.data.facebook[0] &&
                            <a 
                                href={`${homepage.data.facebook[0].text}`}
                                target='_blank'
                                rel='noreferrer'>
                                <FacebookSVG />
                            </a>
                        }
                        {
                            homepage.data.instagram[0] &&
                            <a 
                                href={`${homepage.data.instagram[0].text}`}
                                target='_blank'
                                rel='noreferrer'>
                                <InstagramSVG />
                            </a>
                        }
                        {
                            homepage.data.twitter[0] &&
                            <a 
                                href={`${homepage.data.twitter[0].text}`}
                                target='_blank'
                                rel='noreferrer'>
                                <TwitterSVG />
                            </a>
                        }
                    </div>
                }
            </div> 
        </div>
    );
}

export default Contact;
