import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { closeModal } from '../redux/filtersModalSlice';
import { setFilterCategory } from '../redux/filterCategorySlice';
import { setFilterOperation } from '../redux/filterOperationSlice';
import { setFilterLocation } from '../redux/filterLocationSlice';
import { setFilterPrice } from '../redux/filterPriceSlice';
import { ContextProperties } from '../App';
import Checkboxes from './Checkboxes';
import RangeSlider from './RangeSlider';
import { ReactComponent as AreaSVG } from '../icons/Area.svg';
import { ReactComponent as CheckSVG } from '../icons/Check.svg';
import { ReactComponent as LocationSVG } from '../icons/Location.svg';
import { ReactComponent as PriceTagSVG } from '../icons/PriceTag.svg';
import './Filters.css';

function Filters() {
  const properties = React.useContext(ContextProperties);
  const dispatch = useDispatch();
  const filtersModal = useSelector(state => state.filtersModal.value);
  const [categories, setCategories] = useState([]);
  const [operations, setOperations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [priceMinMax, setPriceMinMax] = useState([0, 0]);
  const [width, setWidth] = useState(window.innerWidth);

  const [openDropdownCategory, setOpenDropdownCategory] = useState(false);
  const [openDropdownOperation, setOpenDropdownOperation] = useState(false);
  const [openDropdownLocation, setOpenDropdownLocation] = useState(false);
  const [openDropdownPrice, setOpenDropdownPrice] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const isMobile = width <= 768;

  useEffect(() => {
    if (properties) {
      // Set Categories
      const categoriesList = [];
      properties.forEach(property => {
        const isCategoryRepeated = categoriesList.find(category => category.value === property.data.category);
        if (!isCategoryRepeated) {
          categoriesList.push({
            value: property.data.category,
            isChecked: true
          })
        }
      });
      setCategories(categoriesList);

      // Set Operations
      const operationsList = [];
      properties.forEach(property => {
        const isOperationRepeated = operationsList.find(operation => operation.value === property.data.operation);
        if (!isOperationRepeated) {
          operationsList.push({
            value: property.data.operation,
            isChecked: true
          })
        }
      });
      setOperations(operationsList);

      // Set Locations
      const locationsList = [];
      properties.forEach(property => {
        const isLocationRepeated = locationsList.find(location => location.value === property.data.location);
        if (!isLocationRepeated) {
          locationsList.push({
            value: property.data.location,
            isChecked: true
          })
        }
      });
      setLocations(locationsList);

      // Set Prices
      setPriceMinMax(
        [
          Math.min(...properties.map(property => {
            if (property.data.activate_discount && property.data.discount_price) {
              return property.data.discount_price
            } else {
              return property.data.regular_price
            }
          })),
          Math.max(...properties.map(property => {
            if (property.data.activate_discount && property.data.discount_price) {
              return property.data.discount_price
            } else {
              return property.data.regular_price
            }
          }))
        ]
      );
    }
  }, [properties])

  // Set initial global values
  useEffect(() => {
    dispatch(setFilterCategory(categories));
    dispatch(setFilterOperation(operations));
    dispatch(setFilterLocation(locations));
    dispatch(setFilterPrice(priceMinMax));
  }, [dispatch, categories, operations, locations, priceMinMax])

  // Functions to pass to components to update global values
  const dispatchFilterCategory = (newValue) => {
    dispatch(setFilterCategory(newValue));
  }

  const dispatchFilterOperation = (newValue) => {
    dispatch(setFilterOperation(newValue));
  }

  const dispatchFilterLocation = (newValue) => {
    dispatch(setFilterLocation(newValue));
  }

  const dispatchFilterPrice = (newValue) => {
    dispatch(setFilterPrice(newValue));
  }

  return (
    <div>
      {
        isMobile &&
        <div className={`modal ${filtersModal?'modal-open':''}`}>
          <div className='modal-container'>
            <div 
              className='modal-close-container'
              onClick={() => dispatch(closeModal())}>
              <div className='modal-close-item'>
                <div className='modal-close-element'></div>
              </div>
            </div>
            <div className='modal-separator'></div>
            <Checkboxes options={categories} dispatchFunction={dispatchFilterCategory} title={'Categoría'} />
            <div className='modal-separator'></div>
            <Checkboxes options={operations} dispatchFunction={dispatchFilterOperation} title={'Tipo de operación'} />
            <div className='modal-separator'></div>
            <Checkboxes options={locations} dispatchFunction={dispatchFilterLocation} title={'Ubicación'} />
            <div className='modal-separator'></div>
            <RangeSlider rangeValue={priceMinMax} dispatchFunction={dispatchFilterPrice} title={'Precio (MXN/USD)'} />
            <div className='modal-separator'></div>
            <button className="filters-btn" onClick={() => dispatch(closeModal())}>Aplicar filtros</button>
          </div>
        </div>
      }
      {
        !isMobile &&
        <div className='filter-bar'>
          <button
            className='filter-btn'
            onClick={() => setOpenDropdownCategory(!openDropdownCategory)}>
            <AreaSVG className='icon-regular color-gray' />
            <h5 className='filter-text | h5-title-small color-gray'>Categoría</h5>
            <div className={`dropdown ${openDropdownCategory?'dropdown-open':''}`}>
              <Checkboxes options={categories} dispatchFunction={dispatchFilterCategory} />
            </div>
          </button>
          <button
            className='filter-btn'
            onClick={() => setOpenDropdownOperation(!openDropdownOperation)}>
            <CheckSVG className='icon-regular color-gray' />
            <h5 className='filter-text | h5-title-small color-gray'>Operación</h5>
            <div className={`dropdown ${openDropdownOperation?'dropdown-open':''}`}>
              <Checkboxes options={operations} dispatchFunction={dispatchFilterOperation} />
            </div>
          </button>
          <button
            className='filter-btn'
            onClick={() => setOpenDropdownLocation(!openDropdownLocation)}>
            <LocationSVG className='icon-regular color-gray' />
            <h5 className='filter-text | h5-title-small color-gray'>Ubicación</h5>
            <div className={`dropdown ${openDropdownLocation?'dropdown-open':''}`}>
              <Checkboxes options={locations} dispatchFunction={dispatchFilterLocation} />
            </div>
          </button>
          <button
            className='filter-btn'
            onClick={() => setOpenDropdownPrice(!openDropdownPrice)}>
            <PriceTagSVG className='icon-regular color-gray' />
            <h5 className='filter-text | h5-title-small color-gray'>Precio</h5>
            <div className={`dropdown ${openDropdownPrice?'dropdown-open':''}`}>
              <RangeSlider rangeValue={priceMinMax} dispatchFunction={dispatchFilterPrice} />
            </div>
          </button>
        </div>
      }
    </div>
      
  )
}

export default Filters;