import { createSlice } from "@reduxjs/toolkit";

export const filterOperationSlice = createSlice({
    name: 'filterOperation',
    initialState: {
        value: [],
    },
    reducers: {
        setFilterOperation: (state, action) => {state.value = action.payload},
    },
})

export const { setFilterOperation } = filterOperationSlice.actions;

export default filterOperationSlice.reducer;