import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './Checkboxes.css';

function Checkboxes(options) {
    const [optionsList, setOptionsList] = useState([]);

    useEffect(() => {
        setOptionsList(options.options);
    }, [options.options])

    useEffect(() => {
        options.dispatchFunction(optionsList);
    }, [options, optionsList])

    const handleCheckOptions = event => {
        const optionIndex = options.options.findIndex(option => 
            option.value === event.target.name  
        );
        const newOptionsList = optionsList.map(option => Object.assign({}, option));
        newOptionsList[optionIndex].isChecked = event.target.checked;
        setOptionsList(newOptionsList);
    }

    return (
        <>
            <h2 className='filter-title'>{options.title}</h2>
            <Box sx={{ display: 'flex' }}>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                    {
                        optionsList.map((option, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox 
                                        checked={option.isChecked} 
                                        onChange={handleCheckOptions}
                                        name={option.value}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                opacity: '1',
                                                height: '12px',
                                                color: '#D16400'
                                            }
                                        }}
                                    />
                                    }
                                label={option.value}
                                sx={{
                                    '& .MuiTypography-root': {
                                        fontSize: '14px',
                                        fontFamily: `'Karla', sans-serif`,
                                        fontWeight: '400'
                                    }
                                }}
                            />
                        ))
                    }
                    </FormGroup>
                </FormControl>
            </Box>    
        </>   
    );
}

export default Checkboxes;