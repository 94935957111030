import { configureStore } from "@reduxjs/toolkit";
import filtersModalReducer from './filtersModalSlice';
import filterCategoryReducer from './filterCategorySlice';
import filterOperationReducer from './filterOperationSlice';
import filterLocationReducer from './filterLocationSlice';
import filterPriceReducer from './filterPriceSlice';

export default configureStore({
    reducer: {
        filterCategory: filterCategoryReducer,
        filterOperation: filterOperationReducer,
        filterLocation: filterLocationReducer,
        filtersModal: filtersModalReducer,
        filterPrice: filterPriceReducer,
    },
})