import React, { useState, useEffect } from 'react';
import { ContextHomepage } from '../App';
import './Home.css';

function Home() {
    const homepage = React.useContext(ContextHomepage);
    const [banners, setBanners] = useState([]);
    const [selectedBannerIndex, setSelectedBannerIndex] = useState(0);
    const [selectedBanner, setSelectedBanner] = useState({});
    const [bannerLoaded, setBannerLoaded] = useState(false);

    useEffect(() => {
        if (homepage && homepage.hasOwnProperty('data')) {
            setBanners(homepage.data.banner);
        }
    }, [homepage, banners, setBanners]);

    useEffect(() => {
        if (banners.length > 0) {
            setSelectedBanner(banners[0]);
        }
    }, [banners])

    useEffect(() => {
        const interval = setInterval(() => {
            selectNewBanner(banners);
        }, 5000);
        return () => clearInterval(interval);
    });

    const selectNewBanner = (images) => {
        setBannerLoaded(false);
        setTimeout(() => {
            const condition = selectedBannerIndex < images.length - 1;
            const nextIndex = condition ? selectedBannerIndex + 1 : 0;
            setSelectedBanner(images[nextIndex]);
            setSelectedBannerIndex(nextIndex);
        }, 600);
    }

    return (
        <div className='banner-container'>
            {
                // homepage && selectedImage && selectedImage.photo && selectedImage.photo.url &&
                homepage && selectedBanner && selectedBanner.photo &&
                <>
                    <img
                        className={`banner ${bannerLoaded ? 'banner-loaded' : ''}`}
                        src={selectedBanner.photo.url}
                        alt='Banner'
                        onLoad={() => setBannerLoaded(true)}
                    />
                    <div className='banner-filter'></div>
                    <h1 className={`banner-text ${bannerLoaded ? 'banner-text-loaded' : ''} | h2-title-large`}>
                        {selectedBanner.title[0].text ? selectedBanner.title[0].text : ''}
                    </h1>
                </>
            }
        </div>
    )
}

export default Home;