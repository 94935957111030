import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { ContextProperties } from '../App';
const PropertyCard = lazy(() => import('./PropertyCard'));

function Properties() {
    const properties = React.useContext(ContextProperties);
    const filterCategory = useSelector(state => state.filterCategory.value);
    const filterOperation = useSelector(state => state.filterOperation.value);
    const filterLocation = useSelector(state => state.filterLocation.value);
    const filterPrice = useSelector(state => state.filterPrice.value);
    const [checkedCategories, setCheckedCategories] = useState([]);
    const [checkedOperations, setCheckedOperations] = useState([]);
    const [checkedLocations, setCheckedLocations] = useState([]);

    useEffect(() => {
        setCheckedCategories(
            filterCategory.map(category => category.isChecked ? category.value : null)
        );
        setCheckedOperations(
            filterOperation.map(operation => operation.isChecked ? operation.value : null)
        );
        setCheckedLocations(
            filterLocation.map(location => location.isChecked ? location.value : null)
        );
    }, [filterCategory, filterOperation, filterLocation])

    return (
        <div>
            {
                properties && properties.map((property, index) => {
                    const priceType = 
                        property.data.activate_discount && 
                        property.data.discount_price ?
                        property.data.discount_price :
                        property.data.regular_price; 
                    if (checkedCategories.includes(property.data.category) &&
                        checkedOperations.includes(property.data.operation) &&
                        checkedLocations.includes(property.data.location) &&
                        priceType >= filterPrice[0] && 
                        priceType <= filterPrice[1]) {
                        return (
                            <div key={property.id}>
                                <Suspense key={property.id} fallback={<h1>Cargando...</h1>}>
                                    <PropertyCard {...property} />  
                                </Suspense>
                            </div>
                        )       
                    }
                    return null
                })
            }
        </div>
    )
}

export default Properties;