import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ContextHomepage } from '../App';
import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import VectorComponent from './VectorComponent';
import { ReactComponent as AreaSVG } from '../icons/Area.svg';
import { ReactComponent as ArrowSVG } from '../icons/Arrow.svg';
import { ReactComponent as ArrowSeeMoreSVG } from '../icons/ArrowSeeMore.svg';
import { ReactComponent as CopySVG } from '../icons/Copy.svg';
import { ReactComponent as LocationSVG } from '../icons/Location.svg';
import { ReactComponent as PriceTagSVG } from '../icons/PriceTag.svg';
import { ReactComponent as RibbonSVG } from '../icons/Ribbon.svg';
import { ReactComponent as WhatsappSVG } from '../icons/Whatsapp.svg';
import './PropertyCard.css';

function PropertyCard(data) {
    const homepage = React.useContext(ContextHomepage);
    const images = data.data.gallery;
    const [width, setWidth] = useState(window.innerWidth);
    const [showMoreDescription, setShowMoreDescription] = useState(false);
    const [seeMore, setSeeMore] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [selectedImage, setSelectedImage] = useState(images[0]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const selectNewImage = (images, next) => {
        const condition = next ? selectedImageIndex < images.length - 1 : selectedImageIndex > 0;
        const nextIndex = next ?
            (condition ? selectedImageIndex + 1 : 0) :
            (condition ? selectedImageIndex - 1 : images.length - 1);
        setSelectedImage(images[nextIndex]);
        setSelectedImageIndex(nextIndex);
    }

    const previousImage = () => {
        selectNewImage(images, false);
    }

    const nextImage = () => {
        selectNewImage(images, true);
    }

    const isMobile = width <= 768;

    return (
        <div className='property-card'>
            <div className='property-card-container'>
                <div className='property-card-media-content'>
                    <div 
                        className='property-card-image' 
                        onClick={() => setOpenModal(true)}>
                        {
                            data.data.gallery[0] &&
                            <div className='property-card-photo-container'>
                                <img 
                                    className='property-card-photo'
                                    src={data.data.gallery[0].photo.url} 
                                    alt='' loading='lazy'                                     
                                />
                                {
                                    data.data.gallery.length > 1 &&
                                    <span
                                        className='property-card-photo-text | body-text-small'>
                                        Ver más fotos
                                    </span>    
                                }
                            </div>
                        }
                    </div>
                    <div className='property-card-googlemaps'>
                        { 
                            isMobile &&
                            <a
                                className='googlemaps-link | body-text-medium'
                                href={data.data.googlemaps_link.url} target='_blank' 
                                rel='noreferrer'>
                                <ArrowSVG className='icon-regular' />
                                <span>Ver en Google Maps</span>
                            </a> 
                        }
                        {
                            !isMobile &&
                            data.data.googlemaps_iframe &&
                            <iframe
                                className='googlemaps-iframe'
                                title={data.data.title[0].text} 
                                src={data.data.googlemaps_iframe[0] && (data.data.googlemaps_iframe[0].text.match(/https.*" w/g))[0].slice(0, -3)} 
                                allowFullScreen='' 
                                loading='lazy'
                                referrerPolicy='no-referrer-when-downgrade'>    
                            </iframe>
                        }
                    </div>
                </div>
                <div className='property-card-info-content'>
                    <h3 className='property-card-title | h3-title-medium'>{data.data.title[0].text}</h3>
                    <div className='property-card-description'>
                        {
                            data.data.description[0] &&
                            <p className='property-description | body-text-medium'>
                                {
                                    showMoreDescription
                                    ? data.data.description[0].text
                                    : `${data.data.description[0].text.substring(0, 140)}`
                                }
                                {
                                    showMoreDescription &&
                                    <br />
                                }
                                {
                                    data.data.description[0].text.length >= 140 &&
                                    <span>
                                        {!showMoreDescription ? '... ' : ''}
                                        <button
                                            className='property-description-seemore-btn | body-text-medium' 
                                            onClick={() => setShowMoreDescription(!showMoreDescription)}>
                                            {!showMoreDescription ? 'Ver más' : 'Ver menos'}
                                        </button>
                                    </span>
                                }
                            </p>
                        }
                    </div>
                    <div className='property-card-general-info'>
                        <div className='property-card-buttons'>
                            <div className='price-tag-container | btn'>
                                <PriceTagSVG className='icon-regular color-black' />
                                <div className='prices'>
                                    {
                                        data.data.regular_price &&
                                        <span className={data.data.activate_discount ? 'is-discount' : null}>
                                            ${Number(data.data.regular_price).toLocaleString('en')} {data.data.currency}
                                        </span>
                                    }
                                    {
                                        data.data.activate_discount && 
                                        data.data.discount_price &&
                                        <div>
                                            <span>${Number(data.data.discount_price).toLocaleString('en')}</span>
                                            <span>&nbsp;{data.data.currency}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                {
                                    homepage && homepage.data.whatsapp_main &&
                                    <a 
                                        className='whatsapp-btn | btn'
                                        href={`https://api.whatsapp.com/send?phone=52${homepage.data.whatsapp_main}&text=¡Hola iHomeCancún! Me interesa ${data.data.title[0].text}.`}
                                        target='_blank' 
                                        rel='noreferrer'>
                                        <WhatsappSVG className='icon-regular' />
                                        <span>¡Me interesa!</span>
                                    </a>
                                }
                            </div>
                        </div>
                        <div className='property-card-info'>
                            <p className='info-item | body-text-small'>
                                <LocationSVG className='color-orange-hover' />
                                <span>{data.data.location}</span>   
                            </p>
                            <p className='info-item | body-text-small'>
                                <AreaSVG className='color-orange-hover' />
                                <span>{data.data.area_m2} M2</span>   
                            </p>
                            <div className='info-item-separator'></div>
                            <Link to={`/propiedades/${data.id}/${data.data.title[0].text.replace(/\s+/g, '-').toLowerCase()}`}>
                                <CopySVG />
                            </Link>
                        </div>
                    </div>
                    <div className='property-card-features'>
                        {
                            isMobile && data.data.features && data.data.features.map((feature, index)  => {
                                if (feature.feature_text[0]) {
                                    if (index >=0 && index < 6) {
                                        return (
                                            <div className='feature-item' key={`feature_container_${index}`}>
                                                <VectorComponent key={`feature_icon_${index}`} feature_icon={feature.feature_icon} />
                                                <p className='body-text-small' key={`feature_text_${index}`}>{feature.feature_text[0].text}</p>
                                            </div>
                                        );
                                    } else if(seeMore && index >= 6) {
                                        return (
                                            <div className='feature-item' key={`feature_container_${index}`}>
                                                <VectorComponent key={`feature_icon_${index}`} feature_icon={feature.feature_icon} />
                                                <p className='body-text-small' key={`feature_text_${index}`}>{feature.feature_text[0].text}</p>
                                            </div>
                                        );
                                    }
                                }
                                return undefined;
                            })
                        }
                        {
                            isMobile && data.data.features.length > 6 &&
                            <div className='property-features-seemore-container'>
                                <button className='property-features-seemore-btn | body-text-small' onClick={() => setSeeMore(!seeMore)}>                            
                                    <ArrowSeeMoreSVG className={`property-features-seemore-icon ${seeMore ? 'property-features-seemore-icon-active' : ''}`} />
                                    <span>
                                        {!seeMore ? 'Ver más' : 'Ver menos'}
                                    </span>
                                </button>
                            </div>
                        }
                        {
                            !isMobile && data.data.features && data.data.features.map((feature, index)  => {
                                if (feature.feature_text[0]) { 
                                    return (
                                        <div className='feature-item' key={`feature_container_${index}`}>
                                            <VectorComponent key={`feature_icon_${index}`} feature_icon={feature.feature_icon} />
                                            <p className='body-text-small' key={`feature_text_${index}`}>{feature.feature_text[0].text}</p>
                                        </div>
                                    );
                                }
                                return undefined;
                            })
                        }
                    </div>
                </div>
                {
                    data.data.is_favorite &&
                    <RibbonSVG className='icon-ribbon' />
                }
            </div>
            <Modal 
                open={openModal}
                onClose={() => setOpenModal(false)}>
                <Box 
                    className='modal-gallery'
                    sx={{
                        '&:focus-visible': {
                            outline: 'none'
                        }
                    }}>
                    <div 
                        className='modal-gallery-close-container'
                        onClick={() => setOpenModal(false)}>
                        <div className='modal-gallery-close-item'>
                            <div className='modal-gallery-close-element'></div>
                        </div>
                    </div>
                    <div className='modal-gallery-content'>
                        <button className='modal-gallery-buttons' onClick={previousImage}>
                            <i className='modal-gallery-button modal-gallery-button-left'></i>
                        </button>
                        {
                            data.data.gallery[0] &&
                            <img 
                                className='modal-card-photo' 
                                src={selectedImage.photo.url} 
                                alt=''
                                onClick={nextImage}
                            />
                        }
                        <button className='modal-gallery-buttons' onClick={nextImage}>
                            <i className='modal-gallery-button modal-gallery-button-right'></i>
                        </button>
                    </div>
                    <p style={{textAlign: 'center'}}>{data.data.title[0].text}</p>
                </Box>
            </Modal>
        </div>
    );
}

export default PropertyCard;