import React from 'react';
import { ReactComponent as AirSVG } from '../icons/features/Air.svg';
import { ReactComponent as AirConditionerSVG } from '../icons/features/AirConditioner.svg';
import { ReactComponent as AreaSVG } from '../icons/features/Area.svg';
import { ReactComponent as BalloonsSVG } from '../icons/features/Balloons.svg';
import { ReactComponent as BathSVG } from '../icons/features/Bath.svg';
import { ReactComponent as Bath2SVG } from '../icons/features/Bath2.svg';
import { ReactComponent as BeachSVG } from '../icons/features/Beach.svg';
import { ReactComponent as BedSVG } from '../icons/features/Bed.svg';
import { ReactComponent as Bed2SVG } from '../icons/features/Bed2.svg';
import { ReactComponent as BuildingSVG } from '../icons/features/Building.svg';
import { ReactComponent as BulletpointSVG } from '../icons/features/Bulletpoint.svg';
import { ReactComponent as CarSVG } from '../icons/features/Car.svg';
import { ReactComponent as Car2SVG } from '../icons/features/Car2.svg';
import { ReactComponent as CouchSVG } from '../icons/features/Couch.svg';
import { ReactComponent as FoodSVG } from '../icons/features/Food.svg';
import { ReactComponent as GarageSVG } from '../icons/features/Garage.svg';
import { ReactComponent as GrillSVG } from '../icons/features/Grill.svg';
import { ReactComponent as GroupSVG } from '../icons/features/Group.svg';
import { ReactComponent as GymSVG } from '../icons/features/Gym.svg';
import { ReactComponent as HouseSVG } from '../icons/features/House.svg';
import { ReactComponent as LivingRoomSVG } from '../icons/features/LivingRoom.svg';
import { ReactComponent as LocationSVG } from '../icons/features/Location.svg';
import { ReactComponent as PetsSVG } from '../icons/features/Pets.svg';
import { ReactComponent as PoolSVG } from '../icons/features/Pool.svg';
import { ReactComponent as Pool2SVG } from '../icons/features/Pool2.svg';
import { ReactComponent as SecuritySVG } from '../icons/features/Security.svg';
import { ReactComponent as SecurityCameraSVG } from '../icons/features/SecurityCamera.svg';
import { ReactComponent as ShowerSVG } from '../icons/features/Shower.svg';
import { ReactComponent as Star1SVG } from '../icons/features/Star1.svg';
import { ReactComponent as Star2SVG } from '../icons/features/Star2.svg';
import { ReactComponent as Star3SVG } from '../icons/features/Star3.svg';
import { ReactComponent as SwingSVG } from '../icons/features/Swing.svg';

function VectorComponent(featureIcon) {
    const renderIcon = () => {
        switch(featureIcon.feature_icon) {
            case 'Air':
                return <AirSVG className='regular' />
            case 'AirConditioner':
                return <AirConditionerSVG className='regular' />
            case 'Area':
                return <AreaSVG className='regular' />
            case 'Balloons':
                return <BalloonsSVG className='regular' />
            case 'Bath':
                return <BathSVG className='regular' />
            case 'Bath2':
                    return <Bath2SVG className='regular' />
            case 'Beach':
                return <BeachSVG className='regular' />
            case 'Bed':
                return <BedSVG className='regular' />
            case 'Bed2':
                return <Bed2SVG className='regular' />
            case 'Building':
                return <BuildingSVG className='regular' />
            case 'Bulletpoint':
                return <BulletpointSVG className='regular' />
            case 'Car':
                return <CarSVG className='regular' />
            case 'Car2':
                return <Car2SVG className='regular' />
            case 'Couch':
                    return <CouchSVG className='regular' />
            case 'Food':
                return <FoodSVG className='regular' />
            case 'Garage':
                return <GarageSVG className='regular' />
            case 'Grill':
                return <GrillSVG className='regular' />
            case 'Group':
                return <GroupSVG className='regular' />
            case 'Gym':
                return <GymSVG className='regular' />
            case 'House':
                return <HouseSVG className='regular' />
            case 'LivingRoom':
                return <LivingRoomSVG className='regular' />
            case 'Location':
                return <LocationSVG className='regular' />
            case 'Pets':
                return <PetsSVG className='regular' />
            case 'Pool':
                return <PoolSVG className='regular' />
            case 'Pool2':
                return <Pool2SVG className='regular' />
            case 'Security':
                return <SecuritySVG className='regular' />
            case 'SecurityCamera':
                return <SecurityCameraSVG className='regular' />
            case 'Shower':
                return <ShowerSVG className='regular' />
            case 'Star1':
                return <Star1SVG className='regular' />
            case 'Star2':
                return <Star2SVG className='regular' />
            case 'Star3':
                return <Star3SVG className='regular' />
            case 'Swing':
                return <SwingSVG className='regular' />
            default:
                return <BulletpointSVG className='regular' />
        }
    }

    return (
        <>
            {renderIcon()}
        </>
    );
}

export default VectorComponent;
