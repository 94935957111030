import React, { useState } from "react";
import { ContextHomepage } from '../App';
import './Legal.css';

function Legal() {
    const homepage = React.useContext(ContextHomepage);
    const [showLegalTab, setShowLegalTab] = useState('cookies');

    const renderLegalTab = () => {
        switch (showLegalTab) {
            case 'privacy':
                return (
                    homepage && homepage.data.privacy[0].text &&
                    <p className='legal-text | body-text-large'>{homepage.data.privacy[0].text}</p>
                )
            case 'conditions':
                return (
                    homepage && homepage.data.conditions[0].text &&
                    <p className='legal-text | body-text-large'>{homepage.data.conditions[0].text}</p>
                )
            default:
                return (
                    <>
                        {/* {
                            homepage.data.cookies[0].text &&
                            <p className='legal-text | body-text-large'>{homepage.data.cookies[0].text}</p>
                        } */}
                        <p className='legal-text | body-text-large'>
                            Este sitio web utiliza cookies de terceros que almacenan y recuperan
                            información cuando navegas.
                        </p>
                        <p className='legal-text | body-text-large'>
                            Esta página web utiliza las cookies de terceros (Google) al utilizar
                            Google Maps para ofrecer a los usuarios una vista previa de la
                            ubicación de las propiedades del catálogo.
                        </p>
                        <p className='legal-text | body-text-large'>
                            Las cookies que el usuario autoriza al utilizar esta página son
                            descritas en la siguiente tabla:
                        </p>
                        <table className='legal-table | body-text-small'>
                            <tbody>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Proveedor</th>
                                    <th>Propósito</th>
                                    <th>Caducidad</th>
                                    <th>Tipo</th>
                                </tr>
                                <tr>
                                    <td>1P_JAR</td>
                                    <td>.google.com</td>
                                    <td>Para proporcionar entrega de anuncios o retargeting</td>
                                    <td>1 mes</td>
                                    <td>Cookie de terceros</td>
                                </tr>
                                <tr>
                                    <td>NID</td>
                                    <td>.google.com</td>
                                    <td>Para proporcionar entrega de anuncios o retargeting, guardar preferencias de usuario</td>
                                    <td>6 mes</td>
                                    <td>Cookie de terceros</td>
                                </tr>
                            </tbody>
                        </table>
                        <p className='legal-text | body-text-large'>
                            Entendemos que hay usuarios que desean no dar autorización para
                            el uso de estas cookies. Es por eso que dejamos información sobre
                            como bloquear las cookies. Bloquear estas cookies no debe modificar
                            la experiencia al utilizar esta página.
                        </p>
                        <p className='legal-text | body-text-large'>
                            A continuación se muestran los enlaces con información para gestionar
                            las cookies de los navegadores más utilizados:
                        </p>
                        <ul className='legal-text | body-text-large'>
                            <li className='legal-text-link-container'>
                                <a
                                    className='legal-text-link'
                                    href='https://support.mozilla.org/es/kb/proteccion-antirrastreo-mejorada-en-firefox-para-e' 
                                    target='_blank'
                                    rel='noreferrer'>
                                    Firefox
                                </a>                 
                            </li>
                            <li className='legal-text-link-container'>
                                <a
                                    className='legal-text-link'
                                    href='http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647' 
                                    target='_blank'
                                    rel='noreferrer'>
                                    Chrome
                                </a>                 
                            </li>
                            <li className='legal-text-link-container'>
                                <a
                                    className='legal-text-link'
                                    href='https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer' 
                                    target='_blank'
                                    rel='noreferrer'>
                                    Internet Explorer
                                </a>                 
                            </li>
                            <li className='legal-text-link-container'>
                                <a
                                    className='legal-text-link'
                                    href='https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy' 
                                    target='_blank'
                                    rel='noreferrer'>
                                    Microsoft Edge
                                </a>                 
                            </li>
                            <li className='legal-text-link-container'>
                                <a
                                    className='legal-text-link'
                                    href='https://support.apple.com/es-mx/guide/safari/sfri11471/mac' 
                                    target='_blank'
                                    rel='noreferrer'>
                                    Safari
                                </a>                 
                            </li>
                            <li className='legal-text-link-container'>
                                <a
                                    className='legal-text-link'
                                    href='http://help.opera.com/Windows/11.50/es-ES/cookies.html' 
                                    target='_blank'
                                    rel='noreferrer'>
                                    Opera
                                </a>                 
                            </li>

                        </ul>
                        <p className='legal-text | body-text-large'>
                            En caso de que el navegador de tu preferencia no se encuentre listado,
                            consulte la documentación de dicho navegador.
                        </p>
                    </>
                )
        }
    }

    return (
        <div className='legal-container'>
            <div className='legal-navbar'>
                <button
                    className={
                        `legal-navbar-btn | h3-title-medium
                        ${showLegalTab === 'cookies' ? 'color-orange-hover | legal-navbar-btn-active' : ''}`}
                    onClick={() => setShowLegalTab('cookies')}>
                    Uso de Cookies
                    {
                        showLegalTab === 'cookies' &&
                        <div className='legal-navbar-btn-underline'></div>
                    }
                </button>
                <button
                    className={
                        `legal-navbar-btn | h3-title-medium
                        ${showLegalTab === 'privacy' ? 'color-orange-hover | legal-navbar-btn-active' : ''}`}
                    onClick={() => setShowLegalTab('privacy')}>
                    Aviso de Privacidad
                    {
                        showLegalTab === 'privacy' &&
                        <div className='legal-navbar-btn-underline'></div>
                    }
                </button>
                <button
                    className={
                        `legal-navbar-btn | h3-title-medium
                        ${showLegalTab === 'conditions' ? 'color-orange-hover | legal-navbar-btn-active' : ''}`}
                    onClick={() => setShowLegalTab('conditions')}>
                    Términos y Condiciones
                    {
                        showLegalTab === 'conditions' &&
                        <div className='legal-navbar-btn-underline'></div>
                    }
                </button>
            </div>
            <div className='legal-text-container'>
                {renderLegalTab()}
            </div>
            
        </div>
    );
}

export default Legal;