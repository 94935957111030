import React, {useState, useEffect} from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openModal } from '../redux/filtersModalSlice';
import { ContextHomepage } from '../App';
import Filters from '../components/Filters';
import { ReactComponent as LogoSVG } from '../icons/Logo.svg';
import { ReactComponent as MailSVG } from '../icons/Mail.svg';
import { ReactComponent as WhatsappSVG } from '../icons/Whatsapp.svg';
import './Navbar.css';

function Navbar(showFilters) {
    const dispatch = useDispatch();
    const homepage = React.useContext(ContextHomepage);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const isMobile = width < 768;

    return (
        <div className='navbar-container'>
            <div className='navbar'>
                {
                    homepage &&
                    <div className='navbar-items-container'>
                        {
                            homepage.data.whatsapp_main &&
                            <a 
                                className='body-text-small navbar-item-container'
                                href={`https://api.whatsapp.com/send?phone=52${homepage.data.whatsapp_main}&text=¡Hola iHomeCancún!`}
                                target='_blank'
                                rel='noreferrer'>
                                <WhatsappSVG className='icon-regular' />
                                <span>{homepage.data.whatsapp_main}</span>    
                            </a>
                        }
                        {
                            homepage.data.whatsapp_second &&
                            <a
                                className='body-text-small navbar-item-container'
                                href={`https://api.whatsapp.com/send?phone=52${homepage.data.whatsapp_second}&text=¡Hola iHomeCancún!`}
                                target='_blank'
                                rel='noreferrer'>
                                <WhatsappSVG className='icon-regular' />
                                <span>{homepage.data.whatsapp_second}</span>
                            </a>
                        }
                        {
                            homepage.data.email &&
                            <a 
                                className='body-text-small navbar-item-container'
                                href={`mailto: ${homepage.data.email[0].text}`}
                                target='_blank'
                                rel='noreferrer'>
                                <MailSVG className='icon-regular' />
                                <span>{homepage.data.email[0].text}</span>
                            </a>
                        }
                    </div>  
                }
                <div className='navbar-links-container'>
                    <div className='navbar-icons'>
                        {
                            isMobile &&
                            <button className={`open-filters ${showFilters.showFilters ? 'open-filters-active' : ''}`}
                                onClick={() => dispatch(openModal())}>
                                <div className='open-filters-icon'></div>
                                <div className='open-filters-icon'></div>
                                <div className='open-filters-icon'></div>
                            </button>
                        }
                        <Link
                            className='navbar-icons-logo' 
                            to='/'>
                            <LogoSVG height='47px' />
                        </Link>
                    </div>
                    <div className='navbar-links'>
                        <NavLink 
                            className={navData => (navData.isActive ? `navlink navlink-active` : 'navlink')} 
                            to='/'>
                            Inicio
                        </NavLink>
                        <NavLink 
                            className={navData => (navData.isActive ? `navlink navlink-active` : 'navlink')} 
                            to='/contacto'>
                            Nosotros y Contacto
                        </NavLink>
                    </div>  
                </div>
            </div>
            {
                <div className={`navbar-filter-bar ${showFilters.showFilters ? 'navbar-filter-bar-active' : ''}`}>
                    {
                        showFilters.showFilters &&
                        <Filters />
                    }
                </div>
            }
        </div>
    );
}

export default Navbar;
