import React from 'react';
import { useParams } from 'react-router-dom';
import { ContextProperties } from '../App';
import PropertyCard from '../components/PropertyCard';

function PropertyDetail() {
    const properties = React.useContext(ContextProperties);
    const { propertyId } = useParams();

    return (
        <div>
            {
                properties && properties.map(property => {
                    if (property.id === propertyId) {
                        return <PropertyCard key={property.id} {...property} />
                    }
                    return null;
                })
            }
        </div>
    )
}

export default PropertyDetail;