import { createSlice } from "@reduxjs/toolkit";

export const filterLocationSlice = createSlice({
    name: 'filterLocation',
    initialState: {
        value: [],
    },
    reducers: {
        setFilterLocation: (state, action) => {state.value = action.payload},
    },
})

export const { setFilterLocation } = filterLocationSlice.actions;

export default filterLocationSlice.reducer;